<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-snackbar top v-model="snackbar" timeout="3000"> {{ msg }} </v-snackbar>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="red lighten-2"
        dark
        v-bind="attrs"
        v-on="on"
        @click="getMyVote()"
      >
        投票する
      </v-btn>
    </template>
    <v-card>
      <v-sheet class="text-h6 left-color pa-2">投票</v-sheet>
      <v-card-subtitle class="pa-3">
        {{ items.question }}
      </v-card-subtitle>
      <v-card-text
        ><v-row>
          <v-col v-for="(item, index) in items.select" :key="item.key" cols="12"
            ><v-btn
              block
              color="primary"
              :outlined="!item.selected"
              @click="registVote(index)"
              >{{ item.text }}{{ item.selected ? "(選択中)" : "" }}
            </v-btn></v-col
          ></v-row
        >
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false"> 閉じる </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
export default {
  name: "vote",
  data() {
    return {
      snackbar: false,
      msg: "",
      dialog: false,
      items: { question: "", select: [] },
    };
  },

  props: ["vote", "userId", "eventId"],
  watch:{
    vote(){
      this.getMyVote()
    }
  },
  methods: {
    getMyVote() {
      this.items.question = this.vote.question;
      this.items.select.splice(0);
      this.vote.select.forEach((elem) => {
        this.items.select.push(elem);
      });

      this.items.select.forEach((elem, index) => {
        if (elem.user.includes(this.userId)) {
          this.items.select[index].selected = true;
        } else {
          this.items.select[index].selected = false;
        }
      });
    },
    registVote(index) {
      axios
        .post(process.env.VUE_APP_API_URL + "/regist-event-vote", {
          id: this.eventId,
          userId: this.userId,
          index: index,
        })
        .then((response) => {
          if (response.data.result == "OK") {
            this.getVoteResult();
          }
        });
    },
    getVoteResult() {
      axios
        .get(process.env.VUE_APP_API_URL + "/get-event-vote-result", {
          params: { _id: this.eventId },
        })
        .then((response) => {
          this.snackbar = true;
          this.msg = "投票しました";
          this.$emit("update:vote", response.data.vote);
        });
    },
  },
};
</script>