<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="fixed_btn"
        color="primary"
        fab
        dark
        v-bind="attrs"
        v-on="on"
      >
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-form v-model="valid" ref="form">
        <v-sheet class="text-h6 left-color pa-2">イベント登録</v-sheet>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-img
                v-show="imgUrl"
                max-width="250"
                height="150"
                :src="imgUrl"
              ></v-img>
              <v-file-input
                class="ma-0"
                label="画像の選択"
                v-model="img"
                accept=".png,.jpg,.jpeg"
                :rules="photoRules"
              ></v-file-input>
            </v-col>
            <v-col>
              <v-text-field
                label="タイトル"
                v-model="item.title"
                class="ma-0"
                :rules="rules"
              ></v-text-field>
              <v-text-field
                class="ma-0"
                label="日時"
                v-model="item.date"
                :rules="rules"
              ></v-text-field>
              <v-text-field
                class="ma-0"
                label="場所"
                v-model="item.place"
                :rules="rules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                outlined
                label="詳細"
                v-model="item.detail"
                :rules="rules"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="clear()"> キャンセル </v-btn>
        <v-btn color="primary" text @click="send()"> 登録 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      img: null,
      imgUrl: null,
      item: { title: null, date: null, place: null, detail: null, img: null },
      valid: false,
      rules: [(v) => !!v || "入力してください"],
      photoRules: [
        (value) =>
          !value ||
          value.type == "image/png" ||
          value.type == "image/jpg" ||
          value.type == "image/jpeg" ||
          '"png,jpg,jpeg形式の画像ファイルを選択してください"',
      ],
    };
  },
  methods: {
    clear() {
      this.dialog = false;
      this.img = null;
      this.imgUrl = null;
      this.item.title = null;
      this.item.date = null;
      this.item.place = null;
      this.item.detail = null;
      this.item.img = null;
      this.$refs.form.reset();
    },
    send() {
      if (this.$refs.form.validate()) {
        this.dialog = false;
        this.$emit("my-click", this.item);
        this.clear();
      }
    },
  },
  watch: {
    img() {
      this.item.img = this.img;
      if (this.img == undefined) {
        this.imgUrl = "";
      } else {
        this.imgUrl = URL.createObjectURL(this.img);
      }
    },
  },
};
</script>
<style>
.fixed_btn {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 10000;
}
</style>