<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on">
        投票フォーム設置
      </v-btn>
    </template>
    <v-card>
      <v-form v-model="valid" ref="form">
        <v-sheet class="text-h6 left-color pa-2">投票フォーム</v-sheet>
        <v-card-text class="pa-5">
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="設問"
                v-model="question"
                class="ma-0"
                :rules="rules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" v-for="(item, index) in select" :key="item.key">
              <v-text-field
                class="ma-0"
                :label="'選択肢' + item.key"
                v-model="item.text"
                :rules="rules"
                :append-outer-icon="nowIndex == index ? 'mdi-plus' : ''"
                @click:append-outer="addSelect(index, item.key)"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="clear()"> キャンセル </v-btn>
        <v-btn color="primary" text @click="send()"> 登録 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "voteForm",
  data() {
    return {
      dialog: false,
      question: "",
      select: [{ key: 1, text: "", user:[] }],
      nowIndex: 0,
      valid: false,
      rules: [(v) => !!v || "入力してください"],
    };
  },
  methods: {
    clear() {
      this.dialog = false;
      this.select.splice(0), this.select.push({ key: 1, text: "", user:[] });
      this.nowIndex = 0;
      this.$refs.form.reset();
    },
    addSelect(index, key) {
      this.nowIndex = index + 1;
      this.select.push({ key: key + 1, text: "", user:[] });
    },
    send() {
      if (this.$refs.form.validate()) {
        this.$emit("my-click", this);
        this.dialog = false;
      }
    },
  },
};
</script>