<template>
  <v-container fluid class="down-top-padding">
    <v-snackbar top v-model="snackbar" timeout="3000"> {{ msg }} </v-snackbar>
    <AddEvent @my-click="regist($event)"></AddEvent>
    <v-row dense>
      <v-col v-for="item in eventList" :key="item._id" cols="12">
        <v-card elevation="1">
          <v-sheet class="text-h6 pa-2 left-color">
            {{ item.title
            }}<EditEvent
              :editItem="item"
              v-if="userId == item.registuser"
              @my-click="updateEvent(item._id, $event)"
            ></EditEvent>
            <DeleteEvent
              v-if="userId == item.registuser"
              @my-click="deleteEvent(item._id)"
            ></DeleteEvent
          ></v-sheet>

          <div
            :class="{ 'd-flex flex-no-wrap': !$vuetify.breakpoint.smAndDown }"
          >
            <v-avatar class="ma-5" size="230" tile>
              <v-img :src="item.photo ? item.photo : defaultImg"></v-img>
            </v-avatar>
            <div>
              <v-card-subtitle class="text-subtitle-1"
                >日時：{{ item.date }}<br />場所：{{
                  item.place
                }}</v-card-subtitle
              >
              <v-card-text class="detail">{{ item.detail }} </v-card-text>
              <v-card-actions
                ><VoteFormRegister
                  @my-click="createVote($event, item._id)"
                  v-if="userId == item.registuser && !item.vote"
                ></VoteFormRegister>
                <VoteResult
                  :vote="item.vote"
                  :closeflg.sync="item.closeflg"
                  :id="item._id"
                  v-if="userId == item.registuser && item.vote"
                ></VoteResult>
                <Vote
                  v-if="
                    userId != item.registuser && item.vote && !item.closeflg
                  "
                  :vote.sync="item.vote"
                  :userId="userId"
                  :eventId="item._id"
                  @my-click="registVote()"
                >
                </Vote>
                <v-btn
                  v-if="userId != item.registuser && item.vote && item.closeflg"
                  disabled
                  >投票締切</v-btn
                >
              </v-card-actions>
            </div>
          </div>
          <v-divider></v-divider>
          <v-row class="pl-3"
            ><v-col cols="12" md="6">登録者：{{ item.username }}</v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AddEvent from "@/components/event/AddEvent";
import axios from "axios";
import _ from "lodash";
import DeleteEvent from "@/components/event/DeleteEvent";
import VoteFormRegister from "@/components/event/VoteFormRegister";
import VoteResult from "@/components/event/VoteResult";
import EditEvent from "@/components/event/EditEvent";
import Vote from "@/components/event/Vote";

export default {
  name: "EventPage",

  data: () => ({
    userId: "",
    eventList: [],
    show: false,
    defaultImg: require("@/assets/default.jpg"),
    snackbar: false,
    msg: "",
  }),
  components: {
    AddEvent,
    DeleteEvent,
    VoteFormRegister,
    VoteResult,
    EditEvent,
    Vote,
  },
  methods: {
    regist(event) {
      const senddata = new FormData();
      senddata.append("files", event.img);
      senddata.append("title", event.title);
      senddata.append("date", event.date);
      senddata.append("place", event.place);
      senddata.append("detail", event.detail);

      axios
        .post(process.env.VUE_APP_API_URL + "/regist-event", senddata, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then(() => {
          this.getEventList();
        });
    },
    getEventList() {
      this.eventList.splice(0);
      axios
        .get(process.env.VUE_APP_API_URL + "/get-event-list")
        .then((response) => {
          this.eventList = _.sortBy(response.data, "updatedate").reverse();
        });
    },
    getUserId() {
      axios
        .get(process.env.VUE_APP_API_URL + "/get-user-id")
        .then((response) => {
          this.userId = response.data.id;
        });
    },
    deleteEvent(id) {
      axios
        .post(process.env.VUE_APP_API_URL + "/delete-event", { id: id })
        .then((response) => {
          if (response.data == "OK") this.getEventList();
        });
    },
    updateEvent(id, event) {
      const senddata = new FormData();
      senddata.append("id", id);
      if (event.img) senddata.append("files", event.img);
      senddata.append("title", event.title);
      senddata.append("date", event.date);
      senddata.append("place", event.place);
      senddata.append("detail", event.detail);

      axios
        .post(process.env.VUE_APP_API_URL + "/update-event", senddata, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then(() => {
          this.getEventList();
        });
    },
    registVote() {
      this.msg = "投票しました。";
      this.snackbar = true;
      this.getEventList();
    },
    createVote(event, id) {
      axios
        .post(process.env.VUE_APP_API_URL + "/regist-event-voteform", {
          id: id,
          question: event.question,
          select: event.select,
        })
        .then(() => {
          this.getEventList();
        });
    },
    changeVoteStatus(flg, id) {
      axios
        .post(process.env.VUE_APP_API_URL + "/update-event-closeflg", {
          id: id,
          closeflg: flg,
        })
        .then(() => {
          this.snackbar = true;
          if (flg) this.msg = "投票を締め切りました。";
          else this.msg = "投票を再開しました。";
          this.getEventList();
        });
    },
  },
  mounted() {
    this.getEventList();
    this.getUserId();
  },
};
</script>
<style>
.detail {
  white-space: pre-line;
}
</style>