<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-snackbar top v-model="snackbar" timeout="3000"> {{ msg }} </v-snackbar>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on"> 投票結果 </v-btn>
    </template>
    <v-card>
      <v-sheet class="text-h6 left-color pa-2">投票結果</v-sheet>
      <v-card-subtitle class="pa-3">
        {{ vote.question }}
      </v-card-subtitle>
      <v-card-text>
        <v-row v-for="(item, index) in vote.select" :key="item.key"
          ><v-col cols="2" align="center">{{ item.text }}</v-col
          ><v-col cols="10"
            ><v-progress-linear
              height="30"
              :value="(item.user.length / sumVoteUser) * 100"
              color="blue lighten-2"
              background-color="grey lighten-3"
              @click="getVoteDetail(index)"
              class="pointer"
            >
              <template v-slot:default="{ value }">
                {{ item.user.length }}人({{ Math.floor(value) }}%)
              </template>
            </v-progress-linear></v-col
          >
        </v-row>
        <v-row justify="end" class="pa-3">合計{{ sumVoteUser }}人</v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="!closeflg"
          outlined
          color="primary"
          @click="changeVoteStatus(true)"
        >
          投票締切
        </v-btn>
        <v-btn
          v-else
          color="grey darken-1"
          outlined
          @click="changeVoteStatus(false)"
        >
          投票再開
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="close()"> 閉じる </v-btn>
      </v-card-actions>
      <v-expand-transition>
        <v-card
          v-if="reveal"
          class="transition-fast-in-fast-out v-card--reveal"
          style="height: 100%"
          ><v-sheet>
            <v-card-text class="pb-0">
              <p class="text-h4 text--primary">投票者</p>
              <v-list-item v-for="item in voteUserList" :key="item" dense>
                {{ item }}</v-list-item
              >
            </v-card-text>
            <v-card-actions class="pt-0">
              <v-btn text color="primary" @click="reveal = false">
                閉じる
              </v-btn>
            </v-card-actions>
          </v-sheet>
        </v-card>
      </v-expand-transition>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
export default {
  name: "voteResult",
  components: {},
  data() {
    return {
      snackbar: false,
      msg: "",
      dialog: false,
      reveal: false,
      voteUserList: [],
    };
  },
  computed: {
    sumVoteUser() {
      var sum = 0;
      this.vote.select.forEach((element) => {
        sum = sum + element.user.length;
      });
      return sum;
    },
  },
  props: ["vote", "closeflg", "id"],
  methods: {
    changeVoteStatus() {
      axios
        .post(process.env.VUE_APP_API_URL + "/update-event-closeflg", {
          id: this.id,
          closeflg: !this.closeflg,
        })
        .then(() => {
          this.snackbar = true;
          if (!this.closeflg) this.msg = "投票を締め切りました。";
          else this.msg = "投票を再開しました。";
          this.$emit("update:closeflg", !this.closeflg);
        });
    },
    getVoteDetail(index) {
      axios
        .get(process.env.VUE_APP_API_URL + "/get-event-vote-user", {
          params: { id: this.id, index: index },
        })
        .then((response) => {
          this.voteUserList.splice(0);
          response.data.userList.forEach((elem) => {
            this.voteUserList.push(elem);
          });
        });

      this.reveal = true;
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>
<style>
.pointer {
  cursor: pointer;
}
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
  z-index: 10000;
}
</style>